import {useEffect, useState} from "react";
import {REACT_BASE_URL} from "../../../config";
import {useNavigate, useParams} from "react-router-dom";
import CommonPageTable from "../../../components/CommonPageTable";
import Dropdown from "../../../components/dropdown";
import {requestGet, requestPost} from "../../../services/request";
import CommonPage from "../../../components/CommonPage";
import styled from "styled-components";

function ServiceOnOffEdit(props) {

    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [id, setId] = useState();
    const [useYn, setUseYn] = useState('');
    const [status, setStatus] = useState('');
    const param = useParams();

    const serviceUseYnList = [
        {
            name: "활성",
            value: "y"
        },
        {
            name: "비활성",
            value: "n"
        }
    ]

    const serviceStateList = [
        {
            name: "준비중입니다.",
            value: "준비중입니다."
        },
        {
            name: "서비스 준비중입니다.",
            value: "서비스 준비중입니다."
        },
        {
            name: "서비스가 종료되었습니다.",
            value: "서비스가 종료되었습니다."
        }
    ];

    const getServiceOnOffInfo = async () => {
        try {
            if(param.id){
                const res = await requestGet(REACT_BASE_URL + `/service/detail/${param.id}`, {
                    headers: {
                        'x-auth-token': localStorage.getItem('accessToken')
                    }
                })

                console.log(res);

                if (res.code === 0) {
                    setData(res.data.serviceOnOffDetail)
                    setId(res?.data?.serviceOnOffDetail?.id)
                    setUseYn(res?.data?.serviceOnOffDetail?.useYn)
                    setStatus(res?.data?.serviceOnOffDetail?.serviceState)
                }
            }
        } catch (error) {
            alert(error);
        }
    }

    const updateServiceOnOff = async () => {
        const res = await requestPost(REACT_BASE_URL + `/service/onoff`, {
            id: id,
            useYn: useYn,
            serviceState: status,
        }, {
            headers:{
                'x-auth-token': localStorage.getItem('accessToken')
            }
        })

        if(res.code === 0) {
            alert('변경되었습니다.')
            navigate(`/serviceonoff`)
        }
    }

    useEffect(() => {
        getServiceOnOffInfo()
    }, [])

    return (
        <CommonPage title={'서비스 중단, 종료 설정상세'}>
            <CommonPageTable title={'[서비스 중단, 종료 설정상세]'}>
                <tbody>
                <tr>
                    <th>서비스명</th>
                    <td>{data.title}</td>
                </tr>
                <tr>
                    <th>서비스 활성화 여부</th>
                    <td>
                        <Dropdown
                            value={useYn}
                            onChange={(value) => {
                                setUseYn(value);
                                console.log(value);
                            }}
                            className="dropdown"
                            options={serviceUseYnList}
                        />
                    </td>
                </tr>
                <tr>
                    <th>서비스 상태표시</th>
                    <td>
                        <Dropdown
                            value={status}
                            onChange={(value) => {
                                setStatus(value);
                                console.log(value);
                            }}
                            className="dropdown"
                            options={serviceStateList}
                        />
                    </td>
                </tr>
                <tr>
                <th colSpan={4}>
                    <ButtonWrapper>
                        <Delete onClick={()=>updateServiceOnOff()}>변경하기</Delete>
                    </ButtonWrapper>
                </th>
                </tr>
                </tbody>
            </CommonPageTable>
        </CommonPage>
    );
}

export default ServiceOnOffEdit;

const Register = styled.button`
${({theme})=>theme.button.type1};
  padding:1rem;
  font-weight: bold;
`

const Delete = styled(Register)`
${({theme})=>theme.button.type5};
  padding:1rem;
`

const ButtonWrapper = styled.div`
display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
`