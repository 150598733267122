import {BrowserRouter, Routes, Route} from "react-router-dom";
import React, {Suspense, lazy} from "react";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import PrivateRoute from "./services/PrivateRoute";
import NotFound from "./components/NotFound";
import ServiceOnOffList from "./pages/BoardManage/ServiceOnOff/ServiceOnOffList";
import ServiceOnOffEdit from "./pages/BoardManage/ServiceOnOff/ServiceOnOffEdit";
import Message from "./pages/BoardManage/ServiceOnOff/Message";

function App() {

    const Login = lazy(() => import('./pages/Login/Login'));
    const AllOfSignUpUser = lazy(()=>import('./pages/SignUpUser/AllOfSignUpUser/AllOfSignUpUser'))
    const UserInfoPage = lazy(()=>import('./pages/SignUpUser/AllOfSignUpUser/UserInfoPage'))
    const PetList = lazy(()=>import('./pages/BoardManage/Pet/PetList'))
    const PetEdit = lazy(()=>import('./pages/BoardManage/Pet/PetEdit'))
    const ClaimInsuranceList = lazy(()=>import('./pages/BoardManage/ClaimInsurance/ClaimInsuranceList'));
    const ClaimInsurancePage = lazy(()=>import('./pages/BoardManage/ClaimInsurance/ClaimInsurancePage'));
    const FaqList = lazy(()=>import('./pages/BoardManage/Faq/FaqList'));
    const FaqEdit = lazy(()=>import('./pages/BoardManage/Faq/FaqEdit'));
    const MediaList = lazy(()=>import('./pages/BoardManage/Media/MediaList'));
    const MediaEdit = lazy(()=>import('./pages/BoardManage/Media/MediaEdit'));
    const NoticeEdit = lazy(()=>import('./pages/BoardManage/Notice/NoticeEdit'));
    const NoticeList = lazy(()=>import('./pages/BoardManage/Notice/NoticeList'));
    const PaymentCaseList = lazy(()=>import('./pages/BoardManage/PaymentCase/PaymentCaseList'));
    const PaymentCaseEdit = lazy(()=>import('./pages/BoardManage/PaymentCase/PaymentCaseEdit'));
    const ReceiptOfChangeList = lazy(()=>import('./pages/BoardManage/ReceiptOfChange/ReceiptOfChangeList'));
    const ReceiptOfChangePage = lazy(()=>import('./pages/BoardManage/ReceiptOfChange/ReceiptOfChangePage'));
    const GroupSignUpList = lazy(()=>import('./pages/SignUpInsurance/GroupSignUp/GroupSignUpList'));
    const GroupSignUpPage = lazy(()=>import('./pages/SignUpInsurance/GroupSignUp/GroupSignUpPage'));
    const AllOfJoinInsuranceList = lazy(()=>import('./pages/SignUpInsurance/AllOfJoinInsurance/AllOfJoinInsuranceList'));
    const AllOfJoinInsurancePage = lazy(()=>import('./pages/SignUpInsurance/AllOfJoinInsurance/AllOfJoinInsurancePage'));    
    const InquiryList = lazy(()=>import('./pages/BoardManage/Inquiry/InquiryList'));
    const InquiryPage = lazy(()=>import('./pages/BoardManage/Inquiry/InquiryPage'));    
    const GuaranteeTypeList = lazy(()=>import('./pages/BoardManage/GuaranteeType/GuaranteeTypeList'));
    const GuaranteeTypeEdit = lazy(()=>import('./pages/BoardManage/GuaranteeType/GuaranteeTypeEdit'));
    const GuaranteeAmountList = lazy(()=>import('./pages/BoardManage/GuaranteeAmount/GuaranteeAmountList'));
    const GuaranteeAmountEdit = lazy(()=>import('./pages/BoardManage/GuaranteeAmount/GuaranteeAmountEdit'));    
    const PetBreedList = lazy(()=>import('./pages/BoardManage/PetBreed/PetBreedList'));
    const InsuranceCompanyList = lazy(()=>import('./pages/BoardManage/InsuranceCompany/InsuranceCompanyList'));
    const InsuranceCompanyEdit = lazy(()=>import('./pages/BoardManage/InsuranceCompany/InsuranceCompanyEdit'));
    const InsuranceProductList = lazy(()=>import('./pages/BoardManage/InsuranceProduct/InsuranceProductList'));
    const InsuranceProductEdit = lazy(()=>import('./pages/BoardManage/InsuranceProduct/InsuranceProductEdit'));
    const InsuranceCoverageList = lazy(()=>import('./pages/BoardManage/InsuranceCoverage/InsuranceCoverageList'));
    const InsuranceCoverageEdit = lazy(()=>import('./pages/BoardManage/InsuranceCoverage/InsuranceCoverageEdit'));
    const InsuranceQuestionList = lazy(()=>import('./pages/BoardManage/InsuranceQuestion/InsuranceQuestionList'));
    const InsuranceQuestionEdit = lazy(()=>import('./pages/BoardManage/InsuranceQuestion/InsuranceQuestionEdit'));    
    const InsuranceDogPriceList = lazy(()=>import('./pages/BoardManage/InsurancePrice/InsuranceDogPriceList'));
    const InsuranceCatPriceList = lazy(()=>import('./pages/BoardManage/InsurancePrice/InsuranceCatPriceList'));
    const InsuranceWildPriceList = lazy(()=>import('./pages/BoardManage/InsurancePrice/InsuranceWildPriceList'));
    const InsuranceInquiryList = lazy(()=>import('./pages/BoardManage/InsuranceInquiry/InsuranceInquiryList'));
    const InsuranceInquiryEdit = lazy(()=>import('./pages/BoardManage/InsuranceInquiry/InsuranceInquiryEdit'));
    const JoinPetList = lazy(()=>import('./pages/BoardManage/JoinPet/JoinPetList'));
    const JoinPetEdit = lazy(()=>import('./pages/BoardManage/JoinPet/JoinPetEdit'));
    const JoinWildList = lazy(()=>import('./pages/BoardManage/JoinWild/JoinWildList'));
    const JoinWildEdit = lazy(()=>import('./pages/BoardManage/JoinWild/JoinWildEdit'));
    const ClaimPetList = lazy(()=>import('./pages/BoardManage/ClaimPet/ClaimPetList'));
    const ClaimPetEdit = lazy(()=>import('./pages/BoardManage/ClaimPet/ClaimPetEdit'));
    const PointList = lazy(()=>import('./pages/BoardManage/Point/PointList'));    
    const RecommenderList = lazy(()=>import('./pages/BoardManage/Recommender/RecommenderList'));
    const RecommenderEdit = lazy(()=>import('./pages/BoardManage/Recommender/RecommenderEdit'));
    const DictionaryList = lazy(()=>import('./pages/BoardManage/Dictionary/DictionaryList'));
    const DictionaryEdit = lazy(()=>import('./pages/BoardManage/Dictionary/DictionaryEdit'));
    const DictionaryReg = lazy(()=>import('./pages/BoardManage/Dictionary/DictionaryReg'));
    const ReviewList = lazy(()=>import('./pages/BoardManage/Review/ReviewList'));
    const ReviewEdit = lazy(()=>import('./pages/BoardManage/Review/ReviewEdit'));
    const ClaimReviewList = lazy(()=>import('./pages/BoardManage/ClaimReview/ClaimReviewList'));
    const ClaimReviewEdit = lazy(()=>import('./pages/BoardManage/ClaimReview/ClaimReviewEdit'));    
    const LoginLogList = lazy(()=>import('./pages/BoardManage/LoginLog/LoginLogList'));    
    const CommunityList = lazy(()=>import('./pages/BoardManage/Community/CommunityList'));
    const CommunityEdit = lazy(()=>import('./pages/BoardManage/Community/CommunityEdit'));
    const FirstPostList = lazy(()=>import('./pages/BoardManage/FirstPost/FirstPostList'));
    const FirstPostEdit = lazy(()=>import('./pages/BoardManage/FirstPost/FirstPostEdit'));
    const FirstCommentList = lazy(()=>import('./pages/BoardManage/FirstPost/FirstCommentList'));
    const WithdrawList = lazy(()=>import('./pages/BoardManage/Withdraw/WithdrawList'));
    const ServiceOnOff = lazy(()=>import('./pages/BoardManage/ServiceOnOff/ServiceOnOffList'));

    return (
        <BrowserRouter>
            <Suspense fallback={''}>
                {/*{window.location.pathname !== '/' &&*/}
                {/*    <Header/>*/}
                {/*}*/}
                {/*{window.location.pathname !== '/' &&*/}
                {/*    <SideBar/>*/}
                {/*}*/}
                <Routes>
                    <Route path={'/'} element={<Login/>}/>
                    <Route path={'*'} element={<NotFound/>}/>

                    <Route element={<PrivateRoute authenticated={true}/> }>
                        <Route path={'/allofsignupuser'} element={<AllOfSignUpUser />}/>
                        <Route path={'/allofsignupusers'} element={<UserInfoPage />}/>
                        <Route path={'/userpet'} element={<PetList />} />
                        <Route path={'/userpet/:id'} element={<PetEdit />} />
                        <Route path={'/claiminsuranceinfo'} element={<ClaimInsuranceList />}/>
                        <Route path={'/claiminsuranceinfo/:id'} element={<ClaimInsurancePage />}/>
                        <Route path={'/faq'} element={<FaqList />} />
                        <Route path={'/faq/:id'} element={<FaqEdit />} />
                        <Route path={'/faq/write'} element={<FaqEdit />} />
                        <Route path={'/media'} element={<MediaList />} />
                        <Route path={'/media/:id'} element={<MediaEdit />} />
                        <Route path={'/media/write'} element={<MediaEdit />} />
                        <Route path={'/notice'} element={<NoticeList />}/>
                        <Route path={'/notice/:id'} element={<NoticeEdit />}/>
                        <Route path={'/notice/write'} element={<NoticeEdit />}/>
                        <Route path={'/paymentcase'} element={<PaymentCaseList />}/>
                        <Route path={'/paymentcase/:id'} element={<PaymentCaseEdit />}/>
                        <Route path={'/paymentcase/write'} element={<PaymentCaseEdit />}/>
                        <Route path={'/receiptofchange'} element={<ReceiptOfChangeList />}/>
                        <Route path={'/receiptofchange/:id'} element={<ReceiptOfChangePage />}/>
                        <Route path={'/groupsignup'} element={<GroupSignUpList />}/>
                        <Route path={'/groupsignup/:id'} element={<GroupSignUpPage />}/>
                        <Route path={'/allofjoininsurance'} element={<AllOfJoinInsuranceList/>}/>
                        <Route path={'/allofjoininsurance/:id'} element={<AllOfJoinInsurancePage/>}/>
                        <Route path={'/inquiry'} element={<InquiryList/>}/>
                        <Route path={'/inquiry/:id'} element={<InquiryPage/>}/>                       
                        <Route path={'/guaranteetype'} element={<GuaranteeTypeList />}/>
                        <Route path={'/guaranteetype/:id'} element={<GuaranteeTypeEdit />} />
                        <Route path={'/guaranteetype/write'} element={<GuaranteeTypeEdit />} />
                        <Route path={'/guaranteeamount'} element={<GuaranteeAmountList />}/>
                        <Route path={'/guaranteeamount/:id'} element={<GuaranteeAmountEdit />} />
                        <Route path={'/guaranteeamount/write'} element={<GuaranteeAmountEdit />} />
                        <Route path={'/petbreed'} element={<PetBreedList />} />
                        <Route path={'/insurancecompany'} element={<InsuranceCompanyList/>}/>
                        <Route path={'/insurancecompany/:id'} element={<InsuranceCompanyEdit/>}/>
                        <Route path={'/insurancecompany/write'} element={<InsuranceCompanyEdit/>}/>
                        <Route path={'/insproduct'} element={<InsuranceProductList />} />
                        <Route path={'/insproduct/:id'} element={<InsuranceProductEdit />} />
                        <Route path={'/insproduct/write'} element={<InsuranceProductEdit />} />
                        <Route path={'/inscoverage'} element={<InsuranceCoverageList />} />
                        <Route path={'/inscoverage/:id'} element={<InsuranceCoverageEdit />} />
                        <Route path={'/inscoverage/write'} element={<InsuranceCoverageEdit />} />
                        <Route path={'/insquestion'} element={<InsuranceQuestionList />} />
                        <Route path={'/insquestion/:id'} element={<InsuranceQuestionEdit />} />
                        <Route path={'/insquestion/write'} element={<InsuranceQuestionEdit />} />
                        <Route path={'/insdogprice'} element={<InsuranceDogPriceList />} />
                        <Route path={'/inscatprice'} element={<InsuranceCatPriceList />} />
                        <Route path={'/inswildprice'} element={<InsuranceWildPriceList />} />
                        <Route path={'/insinquiry'} element={<InsuranceInquiryList />} />
                        <Route path={'/insinquiry/:id'} element={<InsuranceInquiryEdit />} />
                        <Route path={'/insinquiry/write'} element={<InsuranceInquiryEdit />} />
                        <Route path={'/joinpet'} element={<JoinPetList />} />
                        <Route path={'/joinpet/:id'} element={<JoinPetEdit />} />
                        <Route path={'/joinwild'} element={<JoinWildList />} />
                        <Route path={'/joinwild/:id'} element={<JoinWildEdit />} />
                        <Route path={'/claimpet'} element={<ClaimPetList />} />
                        <Route path={'/claimpet/:id'} element={<ClaimPetEdit />} />
                        <Route path={'/point'} element={<PointList />} />
                        <Route path={'/recommender'} element={<RecommenderList />} />
                        <Route path={'/recommender/:id'} element={<RecommenderEdit />} />
                        <Route path={'/dictionary'} element={<DictionaryList />} />
                        <Route path={'/dictionary/:id'} element={<DictionaryEdit />} />
                        <Route path={'/dictionary/write'} element={<DictionaryReg />} />
                        <Route path={'/review'} element={<ReviewList />} />
                        <Route path={'/review/:id'} element={<ReviewEdit />} />
                        <Route path={'/claimreview'} element={<ClaimReviewList />} />
                        <Route path={'/claimreview/:id'} element={<ClaimReviewEdit />} />
                        <Route path={'/loginlog'} element={<LoginLogList />} />
                        <Route path={'/community'} element={<CommunityList />} />
                        <Route path={'/community/:id'} element={<CommunityEdit />} />
                        <Route path={'/firstpost'} element={<FirstPostList />} />
                        <Route path={'/firstpost/:id'} element={<FirstPostEdit />} />
                        <Route path={'/firstcomment'} element={<FirstCommentList />} />
                        <Route path={'/withdraw'} element={<WithdrawList />} />
                        <Route path={'/serviceonoff'} element={<ServiceOnOffList />} />
                        <Route path={'/serviceonoff/:id'} element={<ServiceOnOffEdit />} />
                        <Route path={'/serviceonoff/message'} element={<Message />} />

                    </Route>

                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
