import {useEffect, useState} from 'react';
import CommonPage from "../../../components/CommonPage";
import Loading from "../../../components/Loading";
import List from "./components/List";
import {REACT_BASE_URL} from "../../../config";
import {requestGet, requestPost} from "../../../services/request";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";

function ServiceOnOffList(props) {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const getServiceOnOffList  = async () => {
        try {

            const res = await requestGet(REACT_BASE_URL + '/service/list', {
                headers: {
                    'x-auth-token': localStorage.getItem('accessToken')
                }
            })

            if (res.code === 0) {
                setLoading(false)
                setData(res.data.serviceOnOffList)
            }
        } catch (error) {
            alert(error);
        }
    }

    const sendEmailToWild = async () => {
        if (window.confirm('맹견보험가입자들에게 메일을 발송하겠습니까?')){
            const res = await requestPost(REACT_BASE_URL + `/service/sendmail`, {
                insType: '맹견보험',
                message: '',
            }, {
                headers:{
                    'x-auth-token': localStorage.getItem('accessToken')
                }
            })

            if(res.code === 0) {
                alert('맹견보험가입자들에게 메일이 발송되었습니다.')
                navigate(`/serviceonoff`)
            }
        }
    }


    const sendEmailToPet = async () => {
        if (window.confirm('삼성 애니펫 가입자들에게 메일을 발송하겠습니까?')){
            const res = await requestPost(REACT_BASE_URL + `/service/sendmail`, {
                insType: '펫보험',
                message: '',
            }, {
                headers:{
                    'x-auth-token': localStorage.getItem('accessToken')
                }
            })

            if(res.code === 0) {
                alert('삼성 애니펫 가입자들에게 메일이 발송되었습니다.')
                navigate(`/serviceonoff`)
            }
        }

    }


    useEffect(() => {
        try {
            setLoading(true)
            getServiceOnOffList();
        } catch (e) {
            setLoading(false)
            window.location.href = '/';
        }
    }, [])

    const navigate = useNavigate()

    return (

        <CommonPage title={'서비스 중단, 종료 설정'}>
            <ButtonContainer>
                <Send1 onClick={()=>sendEmailToPet()}>펫보험 메일발송</Send1>
                <Send2 onClick={()=>sendEmailToWild()}>맹견보험 메일발송</Send2>
                <Send3 onClick={()=>navigate('/serviceonoff/message')}>문자발송</Send3>
            </ButtonContainer>
            {loading ? <Loading />
                :
                <>
                <List data={data}/>
                </>
            }
        </CommonPage>
    );
}

export default ServiceOnOffList;

const Send1 = styled.button`
  ${({theme}) => theme.button.type6};
  padding: 0.8rem 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
`

const Send2 = styled.button`
  ${({theme}) => theme.button.type2};
  padding: 0.8rem 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
`
const Send3 = styled.button`
  ${({theme}) => theme.button.type1};
  padding: 0.8rem 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
`


const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 3rem;

`