// import {ReactComponent as FirstIcon} from "../svg/website_user_list_1.svg";

export const menuData = [
    
    {
        menu: '보험가입 관리',
        subMenu:
            [
                {
                    id: 1,
                    title: '펫보험가입목록',
                    href: '/joinpet?pageNum=1',
                    href2: '/joinpet',
                    icon: ()=>(
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M25 917.479V808.218q0-39.128 20.088-70.622 20.089-31.494 55.825-47.814 74.13-32 134.311-46.565 60.182-14.565 123.915-14.565 64.818 0 124.231 14.282 59.413 14.283 133.543 46.848 35.586 15.598 56.25 47.133 20.664 31.535 20.664 71.272v109.292H24.999Zm735.045 0V809.348q0-64.13-31.718-106.891-31.717-42.761-84.848-73.979 64.478 8.565 121.804 23.5 57.326 14.935 94.196 33.804 34.695 19 55.109 51.414 20.413 32.413 20.413 73.878v106.405H760.045ZM359.271 567.652q-69.532 0-113.228-43.696-43.695-43.696-43.695-113.37t43.72-113.37q43.721-43.695 113.088-43.695 69.366 0 113.344 43.713 43.979 43.713 43.979 113.07 0 69.956-43.839 113.652-43.838 43.696-113.369 43.696Zm380.774-157.353q0 69.396-43.949 113.374-43.949 43.979-113.539 43.979-11.599 0-24.447-1.783-12.848-1.782-25.544-6.348 25.13-26.13 37.63-63.96 12.5-37.829 12.5-84.891 0-47.062-12.587-83.248-12.588-36.186-37.543-65.845 11.565-3.491 24.919-5.774 13.353-2.282 25.071-2.282 69.382 0 113.436 43.991 44.053 43.991 44.053 112.787Z"/></svg>
                    )
                },
                {
                    id: 2,
                    title: '맹견보험가입목록',
                    href: '/joinwild?pageNum=1',
                    href2: '/joinwild',
                    icon: ()=>(
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M-6.783 822.783v-60.028q0-41.713 43.478-67.626 43.479-25.912 113.681-25.912 9.034 0 16.742.217 7.708.218 15.881 1.305-9.13 19.043-13.797 38.842-4.668 19.799-4.668 41.319v71.883H-6.783Zm240 0V751q0-33.696 17.783-61.609 17.782-27.913 50.913-48.478 33.13-20.565 78.644-31.131 45.514-10.565 99.287-10.565 54.807 0 100.038 10.565 45.231 10.566 78.64 31.131 33.13 20.565 50.696 48.478 17.565 27.913 17.565 61.609v71.783H233.217Zm562.045 0v-72.025q0-22.089-4.348-41.778t-12.913-38.166q8.173-1.162 16.002-1.38 7.828-.217 15.997-.217 71.005 0 113.894 25.621 42.889 25.621 42.889 68.162v59.783H795.262Zm-482.827-71.305h335.13v-6q-4.522-34.174-50.5-54.848Q551.087 669.957 480 669.957q-71.087 0-117.065 20.956-45.978 20.957-50.5 55.565v5ZM149.687 640.913q-30.383 0-52.013-21.844-21.63-21.845-21.63-52.52 0-30.854 21.727-52.484 21.728-21.631 52.239-21.631 30.686 0 52.599 21.651 21.913 21.65 21.913 52.784 0 30.218-21.766 52.131-21.765 21.913-53.069 21.913Zm660 0q-30.383 0-52.013-21.844-21.631-21.845-21.631-52.52 0-30.854 21.728-52.484 21.728-21.631 52.239-21.631 30.686 0 52.599 21.651 21.913 21.65 21.913 52.784 0 30.218-21.766 52.131-21.765 21.913-53.069 21.913Zm-329.409-71.131q-52.942 0-90.002-37.059-37.059-37.059-37.059-90.001 0-53.549 37.059-90.027 37.06-36.479 90.002-36.479 53.548 0 90.027 36.479 36.478 36.478 36.478 90.027 0 52.942-36.478 90.001-36.479 37.059-90.027 37.059Zm.356-71.305q23.105 0 39.257-16.28 16.152-16.279 16.152-39.891 0-23.611-16.372-39.48-16.372-15.87-39.32-15.87-23.655 0-39.742 15.98-16.087 15.98-16.087 39.603 0 23.199 15.938 39.569 15.937 16.369 40.174 16.369ZM480 751.478Zm0-308.479Z"/></svg>
                    )
                },
                {
                    id: 3,
                    title: '보험청구',
                    href: '/claimpet?pageNum=1',
                    href2: '/claimpet',
                    icon: ()=>(
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M-6.783 822.783v-60.028q0-41.713 43.478-67.626 43.479-25.912 113.681-25.912 9.034 0 16.742.217 7.708.218 15.881 1.305-9.13 19.043-13.797 38.842-4.668 19.799-4.668 41.319v71.883H-6.783Zm240 0V751q0-33.696 17.783-61.609 17.782-27.913 50.913-48.478 33.13-20.565 78.644-31.131 45.514-10.565 99.287-10.565 54.807 0 100.038 10.565 45.231 10.566 78.64 31.131 33.13 20.565 50.696 48.478 17.565 27.913 17.565 61.609v71.783H233.217Zm562.045 0v-72.025q0-22.089-4.348-41.778t-12.913-38.166q8.173-1.162 16.002-1.38 7.828-.217 15.997-.217 71.005 0 113.894 25.621 42.889 25.621 42.889 68.162v59.783H795.262Zm-482.827-71.305h335.13v-6q-4.522-34.174-50.5-54.848Q551.087 669.957 480 669.957q-71.087 0-117.065 20.956-45.978 20.957-50.5 55.565v5ZM149.687 640.913q-30.383 0-52.013-21.844-21.63-21.845-21.63-52.52 0-30.854 21.727-52.484 21.728-21.631 52.239-21.631 30.686 0 52.599 21.651 21.913 21.65 21.913 52.784 0 30.218-21.766 52.131-21.765 21.913-53.069 21.913Zm660 0q-30.383 0-52.013-21.844-21.631-21.845-21.631-52.52 0-30.854 21.728-52.484 21.728-21.631 52.239-21.631 30.686 0 52.599 21.651 21.913 21.65 21.913 52.784 0 30.218-21.766 52.131-21.765 21.913-53.069 21.913Zm-329.409-71.131q-52.942 0-90.002-37.059-37.059-37.059-37.059-90.001 0-53.549 37.059-90.027 37.06-36.479 90.002-36.479 53.548 0 90.027 36.479 36.478 36.478 36.478 90.027 0 52.942-36.478 90.001-36.479 37.059-90.027 37.059Zm.356-71.305q23.105 0 39.257-16.28 16.152-16.279 16.152-39.891 0-23.611-16.372-39.48-16.372-15.87-39.32-15.87-23.655 0-39.742 15.98-16.087 15.98-16.087 39.603 0 23.199 15.938 39.569 15.937 16.369 40.174 16.369ZM480 751.478Zm0-308.479Z"/></svg>
                    )
                },
                {
                    id: 4,
                    title: '1:1문의',
                    href: '/insinquiry?pageNum=1',
                    href2: '/insinquiry',
                    icon: ()=>(
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M-6.783 822.783v-60.028q0-41.713 43.478-67.626 43.479-25.912 113.681-25.912 9.034 0 16.742.217 7.708.218 15.881 1.305-9.13 19.043-13.797 38.842-4.668 19.799-4.668 41.319v71.883H-6.783Zm240 0V751q0-33.696 17.783-61.609 17.782-27.913 50.913-48.478 33.13-20.565 78.644-31.131 45.514-10.565 99.287-10.565 54.807 0 100.038 10.565 45.231 10.566 78.64 31.131 33.13 20.565 50.696 48.478 17.565 27.913 17.565 61.609v71.783H233.217Zm562.045 0v-72.025q0-22.089-4.348-41.778t-12.913-38.166q8.173-1.162 16.002-1.38 7.828-.217 15.997-.217 71.005 0 113.894 25.621 42.889 25.621 42.889 68.162v59.783H795.262Zm-482.827-71.305h335.13v-6q-4.522-34.174-50.5-54.848Q551.087 669.957 480 669.957q-71.087 0-117.065 20.956-45.978 20.957-50.5 55.565v5ZM149.687 640.913q-30.383 0-52.013-21.844-21.63-21.845-21.63-52.52 0-30.854 21.727-52.484 21.728-21.631 52.239-21.631 30.686 0 52.599 21.651 21.913 21.65 21.913 52.784 0 30.218-21.766 52.131-21.765 21.913-53.069 21.913Zm660 0q-30.383 0-52.013-21.844-21.631-21.845-21.631-52.52 0-30.854 21.728-52.484 21.728-21.631 52.239-21.631 30.686 0 52.599 21.651 21.913 21.65 21.913 52.784 0 30.218-21.766 52.131-21.765 21.913-53.069 21.913Zm-329.409-71.131q-52.942 0-90.002-37.059-37.059-37.059-37.059-90.001 0-53.549 37.059-90.027 37.06-36.479 90.002-36.479 53.548 0 90.027 36.479 36.478 36.478 36.478 90.027 0 52.942-36.478 90.001-36.479 37.059-90.027 37.059Zm.356-71.305q23.105 0 39.257-16.28 16.152-16.279 16.152-39.891 0-23.611-16.372-39.48-16.372-15.87-39.32-15.87-23.655 0-39.742 15.98-16.087 15.98-16.087 39.603 0 23.199 15.938 39.569 15.937 16.369 40.174 16.369ZM480 751.478Zm0-308.479Z"/></svg>
                    )
                },
            ]
    },
    {
        menu: '회원-펫-추천인 정보',
        subMenu:
            [
                {
                    id: 1,
                    title: '회원 관리',
                    href: '/allofsignupuser?pageNum=1',
                    href2: '/allofsignupuser',
                    icon: ()=> (
                            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M25 917.479V808.218q0-39.128 20.088-70.622 20.089-31.494 55.825-47.814 74.13-32 134.311-46.565 60.182-14.565 123.915-14.565 64.818 0 124.231 14.282 59.413 14.283 133.543 46.848 35.586 15.598 56.25 47.133 20.664 31.535 20.664 71.272v109.292H24.999Zm735.045 0V809.348q0-64.13-31.718-106.891-31.717-42.761-84.848-73.979 64.478 8.565 121.804 23.5 57.326 14.935 94.196 33.804 34.695 19 55.109 51.414 20.413 32.413 20.413 73.878v106.405H760.045ZM359.271 567.652q-69.532 0-113.228-43.696-43.695-43.696-43.695-113.37t43.72-113.37q43.721-43.695 113.088-43.695 69.366 0 113.344 43.713 43.979 43.713 43.979 113.07 0 69.956-43.839 113.652-43.838 43.696-113.369 43.696Zm380.774-157.353q0 69.396-43.949 113.374-43.949 43.979-113.539 43.979-11.599 0-24.447-1.783-12.848-1.782-25.544-6.348 25.13-26.13 37.63-63.96 12.5-37.829 12.5-84.891 0-47.062-12.587-83.248-12.588-36.186-37.543-65.845 11.565-3.491 24.919-5.774 13.353-2.282 25.071-2.282 69.382 0 113.436 43.991 44.053 43.991 44.053 112.787Z"/></svg>
                    )
                },
                {
                    id: 2,
                    title: '펫 관리',
                    href: '/userpet?pageNum=1',
                    href2: '/userpet',
                    icon: ()=>(
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M-6.783 822.783v-60.028q0-41.713 43.478-67.626 43.479-25.912 113.681-25.912 9.034 0 16.742.217 7.708.218 15.881 1.305-9.13 19.043-13.797 38.842-4.668 19.799-4.668 41.319v71.883H-6.783Zm240 0V751q0-33.696 17.783-61.609 17.782-27.913 50.913-48.478 33.13-20.565 78.644-31.131 45.514-10.565 99.287-10.565 54.807 0 100.038 10.565 45.231 10.566 78.64 31.131 33.13 20.565 50.696 48.478 17.565 27.913 17.565 61.609v71.783H233.217Zm562.045 0v-72.025q0-22.089-4.348-41.778t-12.913-38.166q8.173-1.162 16.002-1.38 7.828-.217 15.997-.217 71.005 0 113.894 25.621 42.889 25.621 42.889 68.162v59.783H795.262Zm-482.827-71.305h335.13v-6q-4.522-34.174-50.5-54.848Q551.087 669.957 480 669.957q-71.087 0-117.065 20.956-45.978 20.957-50.5 55.565v5ZM149.687 640.913q-30.383 0-52.013-21.844-21.63-21.845-21.63-52.52 0-30.854 21.727-52.484 21.728-21.631 52.239-21.631 30.686 0 52.599 21.651 21.913 21.65 21.913 52.784 0 30.218-21.766 52.131-21.765 21.913-53.069 21.913Zm660 0q-30.383 0-52.013-21.844-21.631-21.845-21.631-52.52 0-30.854 21.728-52.484 21.728-21.631 52.239-21.631 30.686 0 52.599 21.651 21.913 21.65 21.913 52.784 0 30.218-21.766 52.131-21.765 21.913-53.069 21.913Zm-329.409-71.131q-52.942 0-90.002-37.059-37.059-37.059-37.059-90.001 0-53.549 37.059-90.027 37.06-36.479 90.002-36.479 53.548 0 90.027 36.479 36.478 36.478 36.478 90.027 0 52.942-36.478 90.001-36.479 37.059-90.027 37.059Zm.356-71.305q23.105 0 39.257-16.28 16.152-16.279 16.152-39.891 0-23.611-16.372-39.48-16.372-15.87-39.32-15.87-23.655 0-39.742 15.98-16.087 15.98-16.087 39.603 0 23.199 15.938 39.569 15.937 16.369 40.174 16.369ZM480 751.478Zm0-308.479Z"/></svg>
                    )
                },
                {
                    id: 3,
                    title: '추천인 관리',
                    href: '/recommender?pageNum=1',
                    href2: '/recommender',
                    icon: ()=>(
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M-6.783 822.783v-60.028q0-41.713 43.478-67.626 43.479-25.912 113.681-25.912 9.034 0 16.742.217 7.708.218 15.881 1.305-9.13 19.043-13.797 38.842-4.668 19.799-4.668 41.319v71.883H-6.783Zm240 0V751q0-33.696 17.783-61.609 17.782-27.913 50.913-48.478 33.13-20.565 78.644-31.131 45.514-10.565 99.287-10.565 54.807 0 100.038 10.565 45.231 10.566 78.64 31.131 33.13 20.565 50.696 48.478 17.565 27.913 17.565 61.609v71.783H233.217Zm562.045 0v-72.025q0-22.089-4.348-41.778t-12.913-38.166q8.173-1.162 16.002-1.38 7.828-.217 15.997-.217 71.005 0 113.894 25.621 42.889 25.621 42.889 68.162v59.783H795.262Zm-482.827-71.305h335.13v-6q-4.522-34.174-50.5-54.848Q551.087 669.957 480 669.957q-71.087 0-117.065 20.956-45.978 20.957-50.5 55.565v5ZM149.687 640.913q-30.383 0-52.013-21.844-21.63-21.845-21.63-52.52 0-30.854 21.727-52.484 21.728-21.631 52.239-21.631 30.686 0 52.599 21.651 21.913 21.65 21.913 52.784 0 30.218-21.766 52.131-21.765 21.913-53.069 21.913Zm660 0q-30.383 0-52.013-21.844-21.631-21.845-21.631-52.52 0-30.854 21.728-52.484 21.728-21.631 52.239-21.631 30.686 0 52.599 21.651 21.913 21.65 21.913 52.784 0 30.218-21.766 52.131-21.765 21.913-53.069 21.913Zm-329.409-71.131q-52.942 0-90.002-37.059-37.059-37.059-37.059-90.001 0-53.549 37.059-90.027 37.06-36.479 90.002-36.479 53.548 0 90.027 36.479 36.478 36.478 36.478 90.027 0 52.942-36.478 90.001-36.479 37.059-90.027 37.059Zm.356-71.305q23.105 0 39.257-16.28 16.152-16.279 16.152-39.891 0-23.611-16.372-39.48-16.372-15.87-39.32-15.87-23.655 0-39.742 15.98-16.087 15.98-16.087 39.603 0 23.199 15.938 39.569 15.937 16.369 40.174 16.369ZM480 751.478Zm0-308.479Z"/></svg>
                    )
                },
                {
                    id: 4,
                    title: '유저로그인LOG',
                    href: '/loginlog?pageNum=1',
                    href2: '/loginlog',
                    icon: ()=>(
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M-6.783 822.783v-60.028q0-41.713 43.478-67.626 43.479-25.912 113.681-25.912 9.034 0 16.742.217 7.708.218 15.881 1.305-9.13 19.043-13.797 38.842-4.668 19.799-4.668 41.319v71.883H-6.783Zm240 0V751q0-33.696 17.783-61.609 17.782-27.913 50.913-48.478 33.13-20.565 78.644-31.131 45.514-10.565 99.287-10.565 54.807 0 100.038 10.565 45.231 10.566 78.64 31.131 33.13 20.565 50.696 48.478 17.565 27.913 17.565 61.609v71.783H233.217Zm562.045 0v-72.025q0-22.089-4.348-41.778t-12.913-38.166q8.173-1.162 16.002-1.38 7.828-.217 15.997-.217 71.005 0 113.894 25.621 42.889 25.621 42.889 68.162v59.783H795.262Zm-482.827-71.305h335.13v-6q-4.522-34.174-50.5-54.848Q551.087 669.957 480 669.957q-71.087 0-117.065 20.956-45.978 20.957-50.5 55.565v5ZM149.687 640.913q-30.383 0-52.013-21.844-21.63-21.845-21.63-52.52 0-30.854 21.727-52.484 21.728-21.631 52.239-21.631 30.686 0 52.599 21.651 21.913 21.65 21.913 52.784 0 30.218-21.766 52.131-21.765 21.913-53.069 21.913Zm660 0q-30.383 0-52.013-21.844-21.631-21.845-21.631-52.52 0-30.854 21.728-52.484 21.728-21.631 52.239-21.631 30.686 0 52.599 21.651 21.913 21.65 21.913 52.784 0 30.218-21.766 52.131-21.765 21.913-53.069 21.913Zm-329.409-71.131q-52.942 0-90.002-37.059-37.059-37.059-37.059-90.001 0-53.549 37.059-90.027 37.06-36.479 90.002-36.479 53.548 0 90.027 36.479 36.478 36.478 36.478 90.027 0 52.942-36.478 90.001-36.479 37.059-90.027 37.059Zm.356-71.305q23.105 0 39.257-16.28 16.152-16.279 16.152-39.891 0-23.611-16.372-39.48-16.372-15.87-39.32-15.87-23.655 0-39.742 15.98-16.087 15.98-16.087 39.603 0 23.199 15.938 39.569 15.937 16.369 40.174 16.369ZM480 751.478Zm0-308.479Z"/></svg>
                    )
                },

            ]
    },
    {
        menu: '포인트 관리',
        subMenu:
            [
                {
                    id: 1,
                    title: '포인트 발급/소진 내역',
                    href: '/point?pageNum=1',
                    href2: '/point',
                    icon: ()=> (
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M546.218 622.999q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM239.784 776q-33.215 0-56.499-23.285Q160 729.431 160 696.216V309.782q0-32.91 23.285-56.347 23.284-23.436 56.499-23.436h613.998q32.678 0 55.948 23.436 23.271 23.437 23.271 56.347v386.434q0 33.215-23.271 56.499Q886.46 776 853.782 776H239.784Zm93.217-73.001H760q0-42 29-71t71-29v-200q-42 0-71-29.282-29-29.283-29-70.718H333.001q0 41.435-29 70.718-29 29.282-71 29.282v200q42 0 71 29t29 71ZM800 909.001H106.218q-32.678 0-55.948-23.271Q27 862.46 27 829.782V376h79.218v453.782H800v79.219ZM233.001 702.999v-400 400Z"/></svg>
                    )
                },
                // {
                //     id: 2,
                //     title: '첫등록 게시글',
                //     href: '/firstpost?pageNum=1',
                //     href2: '/firstpost',
                //     icon: ()=>(
                //         <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M470.391 945.609v-84.566l218.826-218.826 84.566 84.566-218.826 218.826h-84.566Zm-360-203.782v-79.784h326.001v79.784H110.391Zm706.392-58.044-84.566-84.566 29-29q10.826-10.826 27.783-10.826t27.783 10.826l29 29q10.826 10.826 10.826 27.783t-10.826 27.783l-29 29ZM110.391 563.261v-79.218h489.218v79.218H110.391Zm0-178.001v-79.218h489.218v79.218H110.391Z"/></svg>
                //     )
                // },
                // {
                //     id: 3,
                //     title: '첫등록 댓글',
                //     href: '/firstcomment?pageNum=1',
                //     href2: '/firstcomment',
                //     icon: ()=>(
                //         <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M470.391 945.609v-84.566l218.826-218.826 84.566 84.566-218.826 218.826h-84.566Zm-360-203.782v-79.784h326.001v79.784H110.391Zm706.392-58.044-84.566-84.566 29-29q10.826-10.826 27.783-10.826t27.783 10.826l29 29q10.826 10.826 10.826 27.783t-10.826 27.783l-29 29ZM110.391 563.261v-79.218h489.218v79.218H110.391Zm0-178.001v-79.218h489.218v79.218H110.391Z"/></svg>
                //     )
                // },
            ]
    },
    {
        menu: '커뮤니티/후기 관리',
        subMenu:
            [
                {
                    id: 1,
                    title: '반려동물연구소',
                    href: '/dictionary?pageNum=1',
                    href2: '/dictionary',
                    icon: ()=> (
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M546.218 622.999q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM239.784 776q-33.215 0-56.499-23.285Q160 729.431 160 696.216V309.782q0-32.91 23.285-56.347 23.284-23.436 56.499-23.436h613.998q32.678 0 55.948 23.436 23.271 23.437 23.271 56.347v386.434q0 33.215-23.271 56.499Q886.46 776 853.782 776H239.784Zm93.217-73.001H760q0-42 29-71t71-29v-200q-42 0-71-29.282-29-29.283-29-70.718H333.001q0 41.435-29 70.718-29 29.282-71 29.282v200q42 0 71 29t29 71ZM800 909.001H106.218q-32.678 0-55.948-23.271Q27 862.46 27 829.782V376h79.218v453.782H800v79.219ZM233.001 702.999v-400 400Z"/></svg>
                    )
                },
                {
                    id: 2,
                    title: '반려동물 보물',
                    href: '/community?pageNum=1',
                    href2: '/community',
                    icon: ()=>(
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M449.435 624.043h61.695V417.696h-61.695v206.347Zm30.547 107.435q14.343 0 24.811-10.33 10.468-10.331 10.468-24.631t-10.45-24.648q-10.451-10.348-24.793-10.348-14.343 0-24.811 10.385t-10.468 24.631q0 14.245 10.45 24.593 10.451 10.348 24.793 10.348ZM135.694 868.435v-79.783h89.088V498.479q0-89.31 51.761-160.069 51.761-70.758 137.848-89.689v-25.939q0-27.239 19.067-45.207 19.068-17.967 46.307-17.967t46.542 17.967q19.302 17.968 19.302 45.207v25.939q86.653 18.931 138.914 89.407 52.261 70.476 52.261 160.351v290.173h88.087v79.783H135.694ZM480 561.565Zm.283 428.566q-33.95 0-59.378-24.828-25.427-24.829-25.427-59.694h169.609q0 35.261-25.044 59.892-25.043 24.63-59.76 24.63ZM304 788.652h353V498.479q0-74-51-126t-125-52q-74 0-125.5 52t-51.5 126v290.173Z"/></svg>
                    )
                },
                {
                    id: 3,
                    title: '보험가입후기',
                    href: '/review?pageNum=1',
                    href2: '/review',
                    icon: ()=>(
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M449.435 624.043h61.695V417.696h-61.695v206.347Zm30.547 107.435q14.343 0 24.811-10.33 10.468-10.331 10.468-24.631t-10.45-24.648q-10.451-10.348-24.793-10.348-14.343 0-24.811 10.385t-10.468 24.631q0 14.245 10.45 24.593 10.451 10.348 24.793 10.348ZM135.694 868.435v-79.783h89.088V498.479q0-89.31 51.761-160.069 51.761-70.758 137.848-89.689v-25.939q0-27.239 19.067-45.207 19.068-17.967 46.307-17.967t46.542 17.967q19.302 17.968 19.302 45.207v25.939q86.653 18.931 138.914 89.407 52.261 70.476 52.261 160.351v290.173h88.087v79.783H135.694ZM480 561.565Zm.283 428.566q-33.95 0-59.378-24.828-25.427-24.829-25.427-59.694h169.609q0 35.261-25.044 59.892-25.043 24.63-59.76 24.63ZM304 788.652h353V498.479q0-74-51-126t-125-52q-74 0-125.5 52t-51.5 126v290.173Z"/></svg>
                    )
                },
                {
                    id: 4,
                    title: '보험금청구후기',
                    href: '/claimreview?pageNum=1',
                    href2: '/claimreview',
                    icon: ()=>(
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M449.435 624.043h61.695V417.696h-61.695v206.347Zm30.547 107.435q14.343 0 24.811-10.33 10.468-10.331 10.468-24.631t-10.45-24.648q-10.451-10.348-24.793-10.348-14.343 0-24.811 10.385t-10.468 24.631q0 14.245 10.45 24.593 10.451 10.348 24.793 10.348ZM135.694 868.435v-79.783h89.088V498.479q0-89.31 51.761-160.069 51.761-70.758 137.848-89.689v-25.939q0-27.239 19.067-45.207 19.068-17.967 46.307-17.967t46.542 17.967q19.302 17.968 19.302 45.207v25.939q86.653 18.931 138.914 89.407 52.261 70.476 52.261 160.351v290.173h88.087v79.783H135.694ZM480 561.565Zm.283 428.566q-33.95 0-59.378-24.828-25.427-24.829-25.427-59.694h169.609q0 35.261-25.044 59.892-25.043 24.63-59.76 24.63ZM304 788.652h353V498.479q0-74-51-126t-125-52q-74 0-125.5 52t-51.5 126v290.173Z"/></svg>
                    )
                },
                
            ]
    },
    {
        menu: '게시판 관리',
        subMenu:
            [
                
                {
                    id: 1,
                    title: '공지',
                    href: '/notice?pageNum=1',
                    href2: '/notice',
                    icon: ()=>(
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M449.435 624.043h61.695V417.696h-61.695v206.347Zm30.547 107.435q14.343 0 24.811-10.33 10.468-10.331 10.468-24.631t-10.45-24.648q-10.451-10.348-24.793-10.348-14.343 0-24.811 10.385t-10.468 24.631q0 14.245 10.45 24.593 10.451 10.348 24.793 10.348ZM135.694 868.435v-79.783h89.088V498.479q0-89.31 51.761-160.069 51.761-70.758 137.848-89.689v-25.939q0-27.239 19.067-45.207 19.068-17.967 46.307-17.967t46.542 17.967q19.302 17.968 19.302 45.207v25.939q86.653 18.931 138.914 89.407 52.261 70.476 52.261 160.351v290.173h88.087v79.783H135.694ZM480 561.565Zm.283 428.566q-33.95 0-59.378-24.828-25.427-24.829-25.427-59.694h169.609q0 35.261-25.044 59.892-25.043 24.63-59.76 24.63ZM304 788.652h353V498.479q0-74-51-126t-125-52q-74 0-125.5 52t-51.5 126v290.173Z"/></svg>
                    )
                },
                {
                    id: 2,
                    title: '자주하는 질문 관리',
                    href: '/faq',
                    href2: '/faq',
                    icon: ()=>(
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M479.854 790.131q19.798 0 32.69-12.462 12.891-12.463 12.891-32.262 0-19.798-12.745-32.973-12.745-13.174-32.544-13.174-19.798 0-32.69 13.157-12.891 13.156-12.891 32.955t12.745 32.279q12.745 12.48 32.544 12.48Zm-36.463-166.566h79.218V360.956h-79.218v262.609Zm36.953 366.566q-86.203 0-161.506-32.395-75.302-32.395-131.741-88.833-56.438-56.439-88.833-131.738-32.395-75.299-32.395-161.587 0-86.288 32.395-161.665t88.745-131.345q56.349-55.968 131.69-88.616 75.34-32.648 161.676-32.648 86.335 0 161.779 32.604t131.37 88.497q55.926 55.893 88.549 131.452 32.623 75.559 32.623 161.877 0 86.281-32.648 161.575-32.648 75.293-88.616 131.478-55.968 56.186-131.426 88.765-75.459 32.579-161.662 32.579Zm.156-79.218q139.239 0 236.826-97.732 97.587-97.732 97.587-237.681 0-139.239-97.4-236.826-97.399-97.587-237.796-97.587-139.021 0-236.826 97.4-97.804 97.399-97.804 237.796 0 139.021 97.732 236.826 97.732 97.804 237.681 97.804ZM480 576Z"/></svg>
                    )
                },
                {
                    id: 3,
                    title: '보험금 지급사례',
                    href: '/paymentcase',
                    href2: '/paymentcase',
                    icon: ()=> (
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M546.218 622.999q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM239.784 776q-33.215 0-56.499-23.285Q160 729.431 160 696.216V309.782q0-32.91 23.285-56.347 23.284-23.436 56.499-23.436h613.998q32.678 0 55.948 23.436 23.271 23.437 23.271 56.347v386.434q0 33.215-23.271 56.499Q886.46 776 853.782 776H239.784Zm93.217-73.001H760q0-42 29-71t71-29v-200q-42 0-71-29.282-29-29.283-29-70.718H333.001q0 41.435-29 70.718-29 29.282-71 29.282v200q42 0 71 29t29 71ZM800 909.001H106.218q-32.678 0-55.948-23.271Q27 862.46 27 829.782V376h79.218v453.782H800v79.219ZM233.001 702.999v-400 400Z"/></svg>
                    )
                },
                {
                    id: 4,
                    title: '언론보도',
                    href: '/media',
                    href2: '/media',
                    icon: ()=> (
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M546.218 622.999q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM239.784 776q-33.215 0-56.499-23.285Q160 729.431 160 696.216V309.782q0-32.91 23.285-56.347 23.284-23.436 56.499-23.436h613.998q32.678 0 55.948 23.436 23.271 23.437 23.271 56.347v386.434q0 33.215-23.271 56.499Q886.46 776 853.782 776H239.784Zm93.217-73.001H760q0-42 29-71t71-29v-200q-42 0-71-29.282-29-29.283-29-70.718H333.001q0 41.435-29 70.718-29 29.282-71 29.282v200q42 0 71 29t29 71ZM800 909.001H106.218q-32.678 0-55.948-23.271Q27 862.46 27 829.782V376h79.218v453.782H800v79.219ZM233.001 702.999v-400 400Z"/></svg>
                    )
                },

            ]
    },
    {
        menu: '보험 관리',
        subMenu:
            [
                {
                    id: 1,
                    title: '보험상품관리',
                    href: '/insproduct',
                    href2: '/insproduct',
                    icon: ()=>(
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M300 768.804q12 0 21-8.88 9-8.881 9-21.12 0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12.239 9 21.12 9 8.88 21 8.88ZM300 606q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-163.043q12 0 21-8.881 9-8.88 9-21.119 0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12.239 9 21.119 9 8.881 21 8.881Zm132 325.847h244v-60H432v60ZM432 606h244v-60H432v60Zm0-163.043h244v-60H432v60ZM182.152 941.978q-27.599 0-47.865-20.265-20.265-20.266-20.265-47.865V278.152q0-27.697 20.265-48.033 20.266-20.337 47.865-20.337h595.696q27.697 0 48.033 20.337 20.337 20.336 20.337 48.033v595.696q0 27.599-20.337 47.865-20.336 20.265-48.033 20.265H182.152Zm0-68.13h595.696V278.152H182.152v595.696Zm0-595.696v595.696-595.696Z"/></svg>
                    )
                },
                {
                    id: 2,
                    title: '보장항목관리',
                    href: '/inscoverage',
                    href2: '/inscoverage',
                    icon: ()=> (
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M142.152 874.326V398.63v475.696ZM382.63 330.5h194.74v-97.13H382.63v97.13ZM142.152 942.696q-27.599 0-47.865-20.336-20.265-20.336-20.265-48.034V398.63q0-27.599 20.265-47.864 20.266-20.266 47.865-20.266H314.5v-97.13q0-27.698 20.266-48.034Q355.031 165 382.63 165h194.74q27.599 0 47.864 20.336Q645.5 205.672 645.5 233.37v97.13h172.348q27.697 0 48.033 20.266 20.337 20.265 20.337 47.864v230.761q-15.674-12.434-32.327-21.511-16.652-9.076-36.043-16.148V398.63H142.152v475.696H487.63q2.8 18.227 9.477 35.462 6.676 17.234 15.35 32.908H142.152ZM732.63 1016q-77.76 0-133.88-55.399-56.12-55.4-56.12-134Q542.63 748 598.75 692t133.88-56q78 0 134 55.867 56 55.868 56 135.133 0 78.435-56 133.718-56 55.282-134 55.282Zm-.5-73.609q10.457 0 17.555-6.597 7.098-6.598 7.098-17.435t-7.198-17.555q-7.198-6.717-16.955-6.717-10.956 0-17.554 6.717-6.598 6.718-6.598 17.555t6.598 17.435q6.598 6.597 17.054 6.597Zm-18.934-90.021h37.869v-143h-37.869v143Z"/></svg>
                    )
                },
                {
                    id: 3,
                    title: '체크리스트 관리',
                    href: '/insquestion',
                    href2: '/insquestion',
                    icon: ()=> (
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M142.152 874.326V398.63v475.696ZM382.63 330.5h194.74v-97.13H382.63v97.13ZM142.152 942.696q-27.599 0-47.865-20.336-20.265-20.336-20.265-48.034V398.63q0-27.599 20.265-47.864 20.266-20.266 47.865-20.266H314.5v-97.13q0-27.698 20.266-48.034Q355.031 165 382.63 165h194.74q27.599 0 47.864 20.336Q645.5 205.672 645.5 233.37v97.13h172.348q27.697 0 48.033 20.266 20.337 20.265 20.337 47.864v230.761q-15.674-12.434-32.327-21.511-16.652-9.076-36.043-16.148V398.63H142.152v475.696H487.63q2.8 18.227 9.477 35.462 6.676 17.234 15.35 32.908H142.152ZM732.63 1016q-77.76 0-133.88-55.399-56.12-55.4-56.12-134Q542.63 748 598.75 692t133.88-56q78 0 134 55.867 56 55.868 56 135.133 0 78.435-56 133.718-56 55.282-134 55.282Zm-.5-73.609q10.457 0 17.555-6.597 7.098-6.598 7.098-17.435t-7.198-17.555q-7.198-6.717-16.955-6.717-10.956 0-17.554 6.717-6.598 6.718-6.598 17.555t6.598 17.435q6.598 6.597 17.054 6.597Zm-18.934-90.021h37.869v-143h-37.869v143Z"/></svg>
                    )
                },
            ]
    },
    {
        menu: '보험료 테이블 관리',
        subMenu:
            [
                {
                    id: 1,
                    title: '강아지 보험료 테이블 관리',
                    href: '/insdogprice?pageNum=1',
                    href2: '/insdogprice',
                    icon: ()=>(
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M300 768.804q12 0 21-8.88 9-8.881 9-21.12 0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12.239 9 21.12 9 8.88 21 8.88ZM300 606q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-163.043q12 0 21-8.881 9-8.88 9-21.119 0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12.239 9 21.119 9 8.881 21 8.881Zm132 325.847h244v-60H432v60ZM432 606h244v-60H432v60Zm0-163.043h244v-60H432v60ZM182.152 941.978q-27.599 0-47.865-20.265-20.265-20.266-20.265-47.865V278.152q0-27.697 20.265-48.033 20.266-20.337 47.865-20.337h595.696q27.697 0 48.033 20.337 20.337 20.336 20.337 48.033v595.696q0 27.599-20.337 47.865-20.336 20.265-48.033 20.265H182.152Zm0-68.13h595.696V278.152H182.152v595.696Zm0-595.696v595.696-595.696Z"/></svg>
                    )
                },
                {
                    id: 2,
                    title: '맹견 보험료 테이블 관리',
                    href: '/inswildprice?pageNum=1',
                    href2: '/inswildprice',
                    icon: ()=> (
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M142.152 874.326V398.63v475.696ZM382.63 330.5h194.74v-97.13H382.63v97.13ZM142.152 942.696q-27.599 0-47.865-20.336-20.265-20.336-20.265-48.034V398.63q0-27.599 20.265-47.864 20.266-20.266 47.865-20.266H314.5v-97.13q0-27.698 20.266-48.034Q355.031 165 382.63 165h194.74q27.599 0 47.864 20.336Q645.5 205.672 645.5 233.37v97.13h172.348q27.697 0 48.033 20.266 20.337 20.265 20.337 47.864v230.761q-15.674-12.434-32.327-21.511-16.652-9.076-36.043-16.148V398.63H142.152v475.696H487.63q2.8 18.227 9.477 35.462 6.676 17.234 15.35 32.908H142.152ZM732.63 1016q-77.76 0-133.88-55.399-56.12-55.4-56.12-134Q542.63 748 598.75 692t133.88-56q78 0 134 55.867 56 55.868 56 135.133 0 78.435-56 133.718-56 55.282-134 55.282Zm-.5-73.609q10.457 0 17.555-6.597 7.098-6.598 7.098-17.435t-7.198-17.555q-7.198-6.717-16.955-6.717-10.956 0-17.554 6.717-6.598 6.718-6.598 17.555t6.598 17.435q6.598 6.597 17.054 6.597Zm-18.934-90.021h37.869v-143h-37.869v143Z"/></svg>
                    )
                },
                {
                    id: 3,
                    title: '고양이 보험료 테이블 관리',
                    href: '/inscatprice?pageNum=1',
                    href2: '/inscatprice',
                    icon: ()=> (
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M142.152 874.326V398.63v475.696ZM382.63 330.5h194.74v-97.13H382.63v97.13ZM142.152 942.696q-27.599 0-47.865-20.336-20.265-20.336-20.265-48.034V398.63q0-27.599 20.265-47.864 20.266-20.266 47.865-20.266H314.5v-97.13q0-27.698 20.266-48.034Q355.031 165 382.63 165h194.74q27.599 0 47.864 20.336Q645.5 205.672 645.5 233.37v97.13h172.348q27.697 0 48.033 20.266 20.337 20.265 20.337 47.864v230.761q-15.674-12.434-32.327-21.511-16.652-9.076-36.043-16.148V398.63H142.152v475.696H487.63q2.8 18.227 9.477 35.462 6.676 17.234 15.35 32.908H142.152ZM732.63 1016q-77.76 0-133.88-55.399-56.12-55.4-56.12-134Q542.63 748 598.75 692t133.88-56q78 0 134 55.867 56 55.868 56 135.133 0 78.435-56 133.718-56 55.282-134 55.282Zm-.5-73.609q10.457 0 17.555-6.597 7.098-6.598 7.098-17.435t-7.198-17.555q-7.198-6.717-16.955-6.717-10.956 0-17.554 6.717-6.598 6.718-6.598 17.555t6.598 17.435q6.598 6.597 17.054 6.597Zm-18.934-90.021h37.869v-143h-37.869v143Z"/></svg>
                    )
                },
            ]
    },
    {
        menu: '설정',
        subMenu:
            [
                {
                    id: 1,
                    title: '펫품종 관리',
                    href: '/petbreed',
                    href2: '/petbreed',
                    icon: ()=>(
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M300 768.804q12 0 21-8.88 9-8.881 9-21.12 0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12.239 9 21.12 9 8.88 21 8.88ZM300 606q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-163.043q12 0 21-8.881 9-8.88 9-21.119 0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12.239 9 21.119 9 8.881 21 8.881Zm132 325.847h244v-60H432v60ZM432 606h244v-60H432v60Zm0-163.043h244v-60H432v60ZM182.152 941.978q-27.599 0-47.865-20.265-20.265-20.266-20.265-47.865V278.152q0-27.697 20.265-48.033 20.266-20.337 47.865-20.337h595.696q27.697 0 48.033 20.337 20.337 20.336 20.337 48.033v595.696q0 27.599-20.337 47.865-20.336 20.265-48.033 20.265H182.152Zm0-68.13h595.696V278.152H182.152v595.696Zm0-595.696v595.696-595.696Z"/></svg>
                    )
                },
                {
                    id: 2,
                    title: '제휴사 관리',
                    href: '/insurancecompany',
                    href2: '/insurancecompany',
                    icon: ()=> (
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M142.152 874.326V398.63v475.696ZM382.63 330.5h194.74v-97.13H382.63v97.13ZM142.152 942.696q-27.599 0-47.865-20.336-20.265-20.336-20.265-48.034V398.63q0-27.599 20.265-47.864 20.266-20.266 47.865-20.266H314.5v-97.13q0-27.698 20.266-48.034Q355.031 165 382.63 165h194.74q27.599 0 47.864 20.336Q645.5 205.672 645.5 233.37v97.13h172.348q27.697 0 48.033 20.266 20.337 20.265 20.337 47.864v230.761q-15.674-12.434-32.327-21.511-16.652-9.076-36.043-16.148V398.63H142.152v475.696H487.63q2.8 18.227 9.477 35.462 6.676 17.234 15.35 32.908H142.152ZM732.63 1016q-77.76 0-133.88-55.399-56.12-55.4-56.12-134Q542.63 748 598.75 692t133.88-56q78 0 134 55.867 56 55.868 56 135.133 0 78.435-56 133.718-56 55.282-134 55.282Zm-.5-73.609q10.457 0 17.555-6.597 7.098-6.598 7.098-17.435t-7.198-17.555q-7.198-6.717-16.955-6.717-10.956 0-17.554 6.717-6.598 6.718-6.598 17.555t6.598 17.435q6.598 6.597 17.054 6.597Zm-18.934-90.021h37.869v-143h-37.869v143Z"/></svg>
                    )
                },
                {
                    id: 3,
                    title: '월납 조회',
                    href: '/withdraw',
                    href2: '/withdraw',
                    icon: ()=> (
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M142.152 874.326V398.63v475.696ZM382.63 330.5h194.74v-97.13H382.63v97.13ZM142.152 942.696q-27.599 0-47.865-20.336-20.265-20.336-20.265-48.034V398.63q0-27.599 20.265-47.864 20.266-20.266 47.865-20.266H314.5v-97.13q0-27.698 20.266-48.034Q355.031 165 382.63 165h194.74q27.599 0 47.864 20.336Q645.5 205.672 645.5 233.37v97.13h172.348q27.697 0 48.033 20.266 20.337 20.265 20.337 47.864v230.761q-15.674-12.434-32.327-21.511-16.652-9.076-36.043-16.148V398.63H142.152v475.696H487.63q2.8 18.227 9.477 35.462 6.676 17.234 15.35 32.908H142.152ZM732.63 1016q-77.76 0-133.88-55.399-56.12-55.4-56.12-134Q542.63 748 598.75 692t133.88-56q78 0 134 55.867 56 55.868 56 135.133 0 78.435-56 133.718-56 55.282-134 55.282Zm-.5-73.609q10.457 0 17.555-6.597 7.098-6.598 7.098-17.435t-7.198-17.555q-7.198-6.717-16.955-6.717-10.956 0-17.554 6.717-6.598 6.718-6.598 17.555t6.598 17.435q6.598 6.597 17.054 6.597Zm-18.934-90.021h37.869v-143h-37.869v143Z"/></svg>
                    )
                },
                {
                    id: 4,
                    title: '양육비 예측 대쉬보드',
                    href: 'http://admin.petfins.net:8501',
                    href2: 'http://admin.petfins.net:8501',
                    icon: ()=> (
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M142.152 874.326V398.63v475.696ZM382.63 330.5h194.74v-97.13H382.63v97.13ZM142.152 942.696q-27.599 0-47.865-20.336-20.265-20.336-20.265-48.034V398.63q0-27.599 20.265-47.864 20.266-20.266 47.865-20.266H314.5v-97.13q0-27.698 20.266-48.034Q355.031 165 382.63 165h194.74q27.599 0 47.864 20.336Q645.5 205.672 645.5 233.37v97.13h172.348q27.697 0 48.033 20.266 20.337 20.265 20.337 47.864v230.761q-15.674-12.434-32.327-21.511-16.652-9.076-36.043-16.148V398.63H142.152v475.696H487.63q2.8 18.227 9.477 35.462 6.676 17.234 15.35 32.908H142.152ZM732.63 1016q-77.76 0-133.88-55.399-56.12-55.4-56.12-134Q542.63 748 598.75 692t133.88-56q78 0 134 55.867 56 55.868 56 135.133 0 78.435-56 133.718-56 55.282-134 55.282Zm-.5-73.609q10.457 0 17.555-6.597 7.098-6.598 7.098-17.435t-7.198-17.555q-7.198-6.717-16.955-6.717-10.956 0-17.554 6.717-6.598 6.718-6.598 17.555t6.598 17.435q6.598 6.597 17.054 6.597Zm-18.934-90.021h37.869v-143h-37.869v143Z"/></svg>
                    )
                },
                {
                    id: 5,
                    title: '서비스 중단, 종료 관리',
                    href: '/serviceonoff',
                    href2: '/serviceonoff',
                    icon: ()=> (
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M142.152 874.326V398.63v475.696ZM382.63 330.5h194.74v-97.13H382.63v97.13ZM142.152 942.696q-27.599 0-47.865-20.336-20.265-20.336-20.265-48.034V398.63q0-27.599 20.265-47.864 20.266-20.266 47.865-20.266H314.5v-97.13q0-27.698 20.266-48.034Q355.031 165 382.63 165h194.74q27.599 0 47.864 20.336Q645.5 205.672 645.5 233.37v97.13h172.348q27.697 0 48.033 20.266 20.337 20.265 20.337 47.864v230.761q-15.674-12.434-32.327-21.511-16.652-9.076-36.043-16.148V398.63H142.152v475.696H487.63q2.8 18.227 9.477 35.462 6.676 17.234 15.35 32.908H142.152ZM732.63 1016q-77.76 0-133.88-55.399-56.12-55.4-56.12-134Q542.63 748 598.75 692t133.88-56q78 0 134 55.867 56 55.868 56 135.133 0 78.435-56 133.718-56 55.282-134 55.282Zm-.5-73.609q10.457 0 17.555-6.597 7.098-6.598 7.098-17.435t-7.198-17.555q-7.198-6.717-16.955-6.717-10.956 0-17.554 6.717-6.598 6.718-6.598 17.555t6.598 17.435q6.598 6.597 17.054 6.597Zm-18.934-90.021h37.869v-143h-37.869v143Z"/></svg>
                    )
                }
            ]
    }

]
