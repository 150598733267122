import React from "react";
import { Select } from "antd";

const Dropdown = (props) => {
	return (
		<Select defaultValue={props.defaultValue} value={props.value} onChange={props.onChange} style={{ width: 200 }}>
			{props.options.map((option) => (
				<Select.Option value={option.value} key={option.name}>
					{option.name}
				</Select.Option>
			))}
		</Select>
	);
};

export default Dropdown;
