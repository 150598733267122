import React from 'react';
import styled, {keyframes} from 'styled-components'
import {useRecoilValue} from "recoil";
import {handleSidebar} from "../recoil/recoil";
import SideBar from "./SideBar";
import Header from "./Header";

function CommonPage({children, title}) {

    // const showSideBar = useRecoilValue(handleSidebar)

    return (
        <Wrapper>
            <SideBar/>
            <Container>
                <ChildrenBox>
                    <Title>{title}</Title>
                    {children}
                </ChildrenBox>
            </Container>
        </Wrapper>
    );


}

export default CommonPage;

const Wrapper = styled.div`
  display: flex;
  width: 100vw;

`

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-left: 27rem;
  overflow: scroll;

  &::-webkit-scrollbar{
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.6rem;
    background: rgba(255, 255, 255, 0.4);
  }
  &::-webkit-scrollbar-thumb{
    background: rgba(0,0,0,0.3);
    border-radius: 0.6rem;
  }

`

const ChildrenBox = styled.div`
  margin: 4.5rem 0 0 0;
  padding: 0 3rem 5rem 3rem;
  width: 100%;
  min-width: 110rem;

`

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
`