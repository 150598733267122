import {requestPost} from "../../../services/request";
import {REACT_BASE_URL} from "../../../config";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import CommonPage from "../../../components/CommonPage";
import CommonPageTable from "../../../components/CommonPageTable";
import Dropdown from "../../../components/dropdown";
import styled from "styled-components";

function Message(props) {

    const navigate = useNavigate();
    const [message, setMessage] = useState('[펫핀스]')
    const [insType, setInsType] = useState('펫보험')

    const insTypeList = [
        {
            name: "펫보험(애니펫 3년)",
            value: "펫보험"
        },
        {
            name: "맹견보험",
            value: "맹견보험"
        }
    ]

    const handleInput = (e) => {
        setMessage(e.target.value)
    }

    const sendMessage = async () => {
        console.log(insType)
        if (window.confirm(insType + ' 가입자들에게 문자를 발송하시겠습니까?')){
            const res = await requestPost(REACT_BASE_URL + `/service/sendmessage`, {
                insType: insType,
                message: message,
            }, {
                headers:{
                    'x-auth-token': localStorage.getItem('accessToken')
                }
            })

            if(res.code === 0) {
                alert(insType + ' 가입자들에게 문자가 발송되었습니다.')
                navigate(`/serviceonoff`)
            }
        }

    }

    return (
        <CommonPage title={'서비스 중단 알림문자'}>
            <CommonPageTable title={''}>
                <tbody>
                    <tr>
                        <th>보험분류</th>
                        <Dropdown
                            value={insType}
                            onChange={(value) => {
                                setInsType(value);
                                console.log(value);
                            }}
                            className="dropdown"
                            options={insTypeList}
                        />
                    </tr>
                    <tr>
                        <th>문자내용</th>
                        <InputBox>
                            <textarea name={'message'}
                                   value={message}
                                   onChange={handleInput}
                            />
                        </InputBox>
                    </tr>
                    <th colSpan={4}>
                        <ButtonWrapper>
                            <Delete onClick={()=>sendMessage()}>문자발송</Delete>
                        </ButtonWrapper>
                    </th>
                </tbody>
            </CommonPageTable>
        </CommonPage>
    )
}

export default Message;

const InputBox = styled.div`
  width: 100%;
  margin-bottom: 1rem;   
  textarea {
    ${({theme}) => theme.input.type1};
    padding: 1rem;
    width: 40%;
    height: 15rem;
  }

`

const Register = styled.button`
${({theme})=>theme.button.type1};
  padding:1rem;
  font-weight: bold;
`

const Delete = styled(Register)`
${({theme})=>theme.button.type5};
  padding:1rem;
`

const ButtonWrapper = styled.div`
display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
`

