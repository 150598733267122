import React, {useCallback} from 'react';
import styled from "styled-components";
import {ReactComponent as Menu} from "../svg/menu_bar.svg";
import {useRecoilState} from "recoil";
import {handleSidebar} from "../recoil/recoil";
import {useNavigate} from "react-router-dom";

function Header() {

    // const [showSideBar, setShowSideBar] = useRecoilState(handleSidebar)
    //
    // console.log(showSideBar)
    //
    // const changeSideBar = useCallback(() =>{
    //     setShowSideBar(!showSideBar)
    // },[showSideBar])

    const navigate = useNavigate();

    return (
        <Container>
            {/*<MenuBox>*/}
            {/*    <MenuIcon src={'https://wheelchairkorea.cdn.ntruss.com/file/static/img/renewal_shortcut_logo.png'}*/}
            {/*              alt={'휠닷컴미니로고'}*/}
            {/*              onClick={()=>changeSideBar()}*/}
            {/*    />*/}
            {/*</MenuBox>*/}
           <picture onClick={()=>navigate('/')}>
                <source srcSet={'https://wheelchairkorea.cdn.ntruss.com/file/static/press/renewal_nav_logo.webp'}
                        type="image/webp"/>
                <Logo src={'https://kr.object.fin-ncloudstorage.com/petfins/2024/icons/logo%28text%29.png'} alt={'펫핀스로고'} />
            </picture>

        </Container>
    );
}

export default React.memo(Header);

const Container = styled.div`
display: flex;
  width: 100vw;
  align-items: center;
  padding: 2rem 3rem;
  position:fixed;
  z-index: 5;
  background: white;
`

const MenuBox = styled.div`
width:4rem;
  height:4rem;
  border-radius: 100%;
  margin-right: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    background: #D4E0AB;

    transition:0.2s all;
    }
`

const MenuIcon = styled(Menu)`
  cursor: pointer;
  width: 1.7rem;
  
line{
  stroke: #FF4E00;
}
  path{
    stroke: #FF4E00;
  }
`

const Logo = styled.img`
  width: 18.4rem;
  height: 5rem;
  cursor: pointer;
`;
