import axios from 'axios'
import {REACT_BASE_URL} from "../config";


const client = axios.create({
    // baseURL: REACT_BASE_URL,
})


// client.interceptors.request.use((config) => {
//     if (!config.headers['x-auth-token']) {
//         const token = localStorage.getItem('accessToken');
//
//         if (token) {
//             config.headers['x-auth-token'] = token;
//         }
//     }
//     return config;
// });


client.defaults.headers.common['x-auth-token'] = localStorage.getItem('accessToken')


export const setClient = (token) => {
    client.defaults.headers.common['x-auth-token'] = token
}

export default client;
