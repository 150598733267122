import CommonListTable from "../../../../components/CommonListTable";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

function List({data}) {

    const navigate = useNavigate();

    return (
        <CommonListTable width={'100%'}>
            <thead>
            <tr>
                <th>서비스명</th>
                <th>서비스 활성화 여부</th>
                <th>서비스 상태표시</th>
                <th>마지막 상태변경 일시</th>
            </tr>
            </thead>
            <tbody>
            {data.map((one, e)=>{

                return(
                    <tr key={one + e} onClick={() => navigate(`/serviceonoff/${one.id}`)}>
                        <td><center>{one.title}</center></td>
                        <td>{one.useYn === 'y' ? '활성' : '비활성'}</td>
                        <td>{one.serviceState}</td>
                        <td><center>{one.updatedAt}</center></td>
                    </tr>
                )
            })}
            </tbody>
        </CommonListTable>
    );
}

export default List;