import styled from 'styled-components'
import "swiper/css"; //basic
import "swiper/css/navigation";
import "swiper/css/pagination";
import {useSearchParams} from "react-router-dom";
import {useEffect} from "react";

function CommonListTable({children,width}) {


    return (
        <Wrapper width={width}>
            <Table>
                {children}
            </Table>
        </Wrapper>

    );
}

export default CommonListTable;

const Wrapper = styled.div`
  width: ${props => props.width || '170rem'};
  padding: 0 3rem 0 0;
`

const Table = styled.table`
  width: 100%;
  font-size: 1.3rem;
  overflow-x: scroll;
  text-align: center;
  line-height: 1.3;

  ::-webkit-scrollbar{
    display: none;
  }


  .swiper-horizontal {
    height: 12rem;
    width: 13rem;
  }
  
  tr:hover {
    background-color: #FFFACD;
  }

  tr {
    cursor: pointer;
  }

  th {
    padding: 2rem 1rem;
    vertical-align: middle;
    background-color: #4682B4;
    font-weight: bold;
    color: white;
    border: 1px solid #d9d9d9;
  }

  td {
    padding: 1rem;
    vertical-align: middle;
    text-align: left;
    max-width: 30rem;
    border: 1px solid #d9d9d9;    
  }
  .center {
    text-align: center;
  }
`