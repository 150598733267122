import React from 'react';
import styled from "styled-components";

function CommonPageTable({children,title}) {
    return (
        <Wrapper>
            <Table>
                <caption>
                    {title}
                </caption>
                {children}
            </Table>
        </Wrapper>

    );
}

export default CommonPageTable;

const Wrapper = styled.div`
  width: 100%;
`

const Table = styled.table`
  font-size: 1.3rem;
  width: 100%;
  margin-bottom: 3rem;
  line-height: 1.3;

  
  caption {
    text-align: left;
    font-size: 1.6rem;
    padding: 1rem;
  }


  tr {
    width: 100%;
    border-top: 0.5px solid #afafaf;
  }

  th {
    width: 17%;
    text-align: left;
    font-weight: bold;
  }

  td {
    width: 33%;
    
    img{
      width: 50rem;
      height: auto;
    }
  }

  th, td {
    padding: 1.2rem 1.5rem;
    vertical-align: middle;

  }

  input {
    border: 0.5px solid #afafaf;
    padding: 1rem;
    width: 100%;
    outline: none;

  }

  textarea {
    width: 100%;
    padding: 1rem;
    outline: none;

  }

  select {
    outline: none;

  }
  
  img{
    width: 100%;
  }
`
